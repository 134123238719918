import { Component, Input } from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.css'
})
export class BreadcrumbComponent 
{
  @Input() title!:string
  
  constructor
  (
    private navigationService: NavigationService
  ){}

  goToHomePage()
  {
    this.navigationService.navigateTo("/", [null])
  }
}
