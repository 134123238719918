import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CryptoService 
{
  private readonly HOST:string  = environment.BASE_URL
  
  constructor(
    private httpClient:HttpClient
  ) {}

  encryptData(data:string):Observable<{iv:string, encrypted:string}>
  {
    const url = `${this.HOST}/encrypt`

    return this.httpClient.get<{iv:string, encrypted:string}>(url, {params:{data}})
  }

  decryptData(data:string):Observable<string>
  {
    const url = `${this.HOST}/decrypt`

    return this.httpClient.get<string>(url, {params:{data}})
  }
}
