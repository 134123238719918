import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient,
    private sharedService: SharedService
  ) { }

  downloadProcess(payload:{ref:string, fileName:string, type:string, path:string, liste_pj?:string[]})
  {
    this.sharedService.blurPage()
    
    this.download(payload).subscribe((blob:Blob) => this.downloadBlob(blob, payload))
  }

  download(payload:{ref:string, fileName:string, type:string, path:string, liste_pj?:string[]}): Observable<Blob>
  {
    const url = `${this.HOST}/download`

    return this.httpClient.get(url, 
      {
        params:
        {
          payload:JSON.stringify(payload)
        },
        responseType: 'blob'
      }
    )
  }

  downloadBlob(blob:Blob, payload:{ref:string, fileName:string, type:string, path:string, liste_pj?:string[]})
  {
    if( blob?.size == 0 )
    {
      alert("fichier non trouvé")
      return this.sharedService.focusPage()
    }

    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")

    link.href = blobUrl
    link.download = (payload.type == "all_pj_message") ? payload.ref : payload.fileName
    link.click()
    window.URL.revokeObjectURL(link.toString())

    this.sharedService.focusPage()
  }
}
