import { ConnexionOut } from "../../interfaces/ConnexionOut";

let isEmpty = false

export function isDataEmpty(data:ConnexionOut)
{
    for (const key in data) 
    {
        if (Object.prototype.hasOwnProperty.call(data, key)) 
        {
            const element = data[key]
            if(element == null || !element) 
            {
                isEmpty = true 
            }
        }
    }

    return isEmpty
}