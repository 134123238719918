import { Component, SimpleChanges } from '@angular/core';
import { DossiersService } from '../../../services/dossiers/dossiers.service';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { Document } from '../../../interfaces/Document';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";
import { getElementsOnPage } from '../../../helpers/functions/GetElementsOnPage';

@Component({
    selector: 'app-dossiers-documents',
    standalone: true,
    templateUrl: './dossiers-documents.component.html',
    styleUrl: './dossiers-documents.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})
export class DossiersDocumentsComponent 
{
  title:string = "Dossiers - Documents" 
  id_user:number
  pageSize:number = getElementsOnPage()
  pageIndex:number = 1
  totalPages!:number
  totalResult!:number

  data_header_table:object = 
  {
    "badge":"",
    "date":"Date",
    "ref":"N°dossier",
    "expediteur":"Expéditeur",
    "etat":"État",
    "msg":"Rattaché au message",
    "fichier":"Fichier",
    "poids":"Poids",
    "telecharger":""
  }
  data_body_table:Document[] = []
  isDataReady:boolean = false

  constructor
  (
    private dossierService: DossiersService,
    private sharedService: SharedService
  )
  {
    this.id_user = this.sharedService.getIdUser()
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getAllDocuments()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getAllDocuments()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getAllDocuments()
  }

  async createPagination(pageSize:number)
  {
    (await 
      this.sharedService.getInfoPagination("dossiers_documents", this.id_user)).subscribe(data => {
      this.totalResult = data[0].total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getAllDocuments(search:string="")
  {
    this.dossierService.getAllDocuments(this.pageIndex, this.pageSize, this.id_user, search).subscribe((data:Document[]) => {
      this.data_body_table = data.map(document => {
        if(document.fichier.startsWith(".")) document.fichier = document.url_doc.split("/").slice(-1)[0]
        document.url_doc = document.url_doc.split("/").slice(-1)[0]
        return document
      })
      this.isDataReady = true
    })
  }

}
