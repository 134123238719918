import { Component } from '@angular/core';
import { AlertComponent } from '../alert/alert.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter } from 'rxjs';
import { SharedService } from '../../services/shared/shared.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { environment } from '../../../environments/environment';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { CryptoService } from '../../services/crypto/crypto.service';


@Component({
    selector: 'app-sidebar',
    standalone: true,
    templateUrl: 'sidebar.component.html',
    styleUrl: 'sidebar.component.css',
    imports: [AlertComponent, RouterLink, CommonModule]
})
export class SidebarComponent
{
    nb_clos!:number
    nb_en_cours!:number
    nb_message_non_lus!:number
    nb_document_non_lus!:number
    nb_message!:number
    nb_document!:number
    nb_need_edit!:number
    isDetailsDossierVisible:boolean = false
    ref!:string|null
    type_ref!:string|null
    idUser!:number
    id_dossier!:number
    lien_guide:string = environment.LINK_GUIDE
    refs_list:string[] = []

    constructor
    (
        private sharedService: SharedService,
        private router: Router,
        private navigationService: NavigationService,
        private dashboardService: DashboardService,
        private cryptoService: CryptoService
    ) 
    {
        this.idUser = this.sharedService.getIdUser()

        this.removeRef()
    }
    
    ngOnInit()
    {
        this.removeRef()
        this.getData()
        this.init()

        setInterval(() => {
            this.getData()
        }, 1000 * 60)
    }

    ngAfterViewInit()
    {
        this.highlightSidebar()

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.init()
        })
    }

    init()
    {
        this.ref = this.sharedService.getParam("ref")?.toString() || ""

        this.setTypeRef()
        this.highlightSidebar()
        this.setIsDetailsDossierVisible()
        
        this.id_dossier = Number(this.sharedService.getParam("id_dossier"))

        if( window.innerWidth < 1400 ) this.sharedService.hideSidebar()
    }

    highlightSidebar()
    {
        const route_split = this.router.url.split("/")[1]
        const url_end = route_split.startsWith("tableau-de-bord") || route_split.length == 0
            ? "dashboard"
            : this.router.url.split("/")[1] 

        this.removeHighlight()

        document.querySelector(`.${url_end}`)?.classList.add("active","collapsed")
    }

    removeHighlight()
    {
        Array.from(document.querySelectorAll(".nav-menu-item-group"))
            .map( e => e.classList.remove("active"))
    }

    setActive(event:Event)
    {
        this.removeHighlight()

        {(event.currentTarget as HTMLElement)?.classList.add("active")}
    }

    setTypeRef()
    {
        this.type_ref = sessionStorage.getItem("type_ref") || null
    }

    setIsDetailsDossierVisible()
    {
        this.isDetailsDossierVisible = this.ref  ? true : false
    }

    removeRef()
    {
        sessionStorage.removeItem("ref")
    }

    getData()
    {
        this.countDossiersClos()
        this.countDossiersEnCours()
        this.countMessages()
        this.countDocuments()
        this.countMessagesNonLus()
        this.countDocumentsNonLus()
        this.getRefDossiers()
    }

    countMessagesNonLus()
    {
        this.dashboardService.countMessagesNonLus(this.idUser).subscribe(data => {
            if(data) this.nb_message_non_lus = data[0].total_result
        })
    }

    countDocumentsNonLus()
    {
        this.dashboardService.countDocumentsNonLus(this.idUser).subscribe(data => {
            if(data) this.nb_document_non_lus = data[0].total_result
        })
    }

    countMessages()
    {
        this.dashboardService.countMessages(this.idUser).subscribe(data => {
            if(data) this.nb_message = data[0].total_result
        })
    }

    countDocuments()
    {
        this.dashboardService.countDocuments(this.idUser).subscribe(data => {
            if(data) this.nb_document = data[0].total_result
        })
    }

    countDossiersEnCours()
    {
        this.dashboardService.countDossiersEnCours(this.idUser).subscribe(data => {
            if(data) this.nb_en_cours = data[0].total_result
        })
    }

    countDossiersClos()
    {
        this.dashboardService.countDossiersClos(this.idUser).subscribe(data => {
            if(data) this.nb_clos = data[0].total_result
        })
    }

    getRefDossiers()
    {
        this.dashboardService.getRefDossiers(this.idUser).subscribe(data => {
            if(data) this.refs_list = data.map(row => row.REF)
        })
    }

    goTo(urlEnd:string)
    {
        this.navigationService.navigateByURL(`tableau-de-bord/${urlEnd}`, [null])
    }

    goToSaisine(ref:string|null)
    {   
        if(ref) this.navigationService.navigateByURL(`dossiers/saisine/ref_${ref}`, [null])
    }

    async newSaisine(id_user:number)
    {       
        const url = environment.LINK_SAISINE

        this.cryptoService.encryptData(id_user.toString()).subscribe(data => {
            const parameterValue = `?data=${data.iv}:${data.encrypted}`
            window.open(url+parameterValue, '_blank')
        })
    }

    displaySidebar()
    {
        this.sharedService.hideSidebar()
    }

    switchToTabletMode()
    {
        return this.sharedService.switchToTabletMode()
    }
}