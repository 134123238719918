import { Routes } from '@angular/router';
import { HomeComponent } from './content/home/home.component';
import { ProfilComponent } from './content/profil/profil.component';
import { ListeActionsComponent } from './content/liste-actions/liste-actions.component';
import { DossiersListeComponent } from './content/dossiers/list/dossiers-liste.component';
import { DossiersDetailsComponent } from './content/dossiers/details/dossier-details.component';
import { DossiersDocumentsComponent } from './content/dossiers/dossiers-documents/dossiers-documents.component';
import { DossiersMessagesComponent } from './content/dossiers/dossiers-messages/dossiers-messages.component';
import { ConnexionComponent } from './connexion/connexion.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { AuthGuard } from './services/route-guard/route-guard.service';
import { NewPassword } from './pages/new-password.component';
import { SaisineComponent } from './content/dossiers/saisine/saisine.component';
import { DossiersClosComponent } from './content/tableau-de-bord/dossiers-clos/dossiers-clos.component';
import { DossiersEnCoursComponent } from './content/tableau-de-bord/dossiers-en-cours/dossiers-en-cours.component';
import { MessagesNonLusComponent } from './content/tableau-de-bord/messages-non-lus/messages-non-lus.component';
import { DocumentsNonLusComponent } from './content/tableau-de-bord/documents-non-lus/documents-non-lus.component';
import { AutologinComponent } from './autologin/autologin.component';



export const routes: Routes = 
[
    {
        path: 'connexion',
        component: ConnexionComponent
    },
    {
        path: 'autologin',
        component: AutologinComponent
    },
    {
        path: 'mot-de-passe',
        component: NewPassword
    },
    {
        path: '',
        component: AppContainerComponent,
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard],
        children:
        [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'tableau-de-bord/dossiers-clos',
                component: DossiersClosComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/dossiers-en-cours',
                component: DossiersEnCoursComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/messages-non-lus',
                component: MessagesNonLusComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/documents-non-consultes',
                component: DocumentsNonLusComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'dossiers',
                component: DossiersListeComponent,
            },
            {
                path: 'dossiers/documents',
                component: DossiersDocumentsComponent,
            },
            {
                path: 'dossiers/messages',
                component: DossiersMessagesComponent,
            },
            {
                path: 'dossiers/details/:ref/:id_dossier/:onglet',
                component: DossiersDetailsComponent,
            },
            {
                path: 'dossiers/saisine/:ref',
                component: SaisineComponent,
            },
            {
                path: 'profil',
                component: ProfilComponent
            },
            {
                path: 'liste-actions',
                component: ListeActionsComponent
            }
        ]
    }

]

