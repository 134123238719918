export const environment = 
{
    production: true,
    BASE_URL: "https://api.abonnes.mediation-eau.fr",
    HOST_SITE : "abonnes.mediation-eau.fr",
    LINK_SAISINE : "https://www.mediation-eau.fr/FR/votre-saisine/saisine.asp",
    ALLOWED_DOMAINS: ["abonnes.mediation-eau.fr"],
    LINK_GUIDE : "https://mediation-eau.fr/userfiles/files/guide/MDE_GuideUtilisation_EspaceAbonnesRepresentants.pdf",
    CONTACT_LINK: "https://mediation-eau.fr/FR/contact/se_sa-nous-contacter.asp"
}
