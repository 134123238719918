import { Component, SimpleChanges } from '@angular/core';
import { Dossiers } from '../../../interfaces/Dossiers';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from "../../../modules/shared/shared.module";
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { getElementsOnPage } from '../../../helpers/functions/GetElementsOnPage';

@Component({
  selector: 'app-dossiers-clos',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dossiers-clos.component.html',
  styleUrl: './dossiers-clos.component.css'
})
export class DossiersClosComponent 
{
  title:string = "Dossiers clos" 
  pageSize:number = getElementsOnPage()
  pageIndex:number = 1
  totalPages!:number
  totalResult!:number
  data_header_table = 
  {
      "badge":"",
      "ref":"N°dossier MDE",
      "nom_dossier":"Nom du dossier",
      "num_contrat":"N°de contrat",
      "date":"Date saisine",
      "etat":"État"
  }
  data_body_table: Dossiers[] = []
  isDataReady:boolean = false
  id_user!:number

  constructor
  (
    private sharedService:SharedService,
    private dashboardService: DashboardService
  ) 
  {
    const idUser = this.sharedService.getIdUser()
    this.id_user = idUser
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getData()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getData()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getData()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("dossiers_clos", this.id_user)).subscribe(data => {
      this.totalResult = data[0].total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getData(search:string="")
  {
    this.dashboardService.getDossiersClos(this.pageIndex, this.pageSize, this.id_user, search).subscribe((data:Dossiers[]) => {
        this.data_body_table = data
        this.isDataReady = true
    })
  }
}
