import { Component, SimpleChanges} from '@angular/core';
import { DossiersService } from '../../../services/dossiers/dossiers.service';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { Dossiers } from '../../../interfaces/Dossiers';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";
import { getElementsOnPage } from '../../../helpers/functions/GetElementsOnPage';

@Component({
    selector: 'app-dossiers-liste',
    standalone: true,
    templateUrl: 'dossiers-liste.component.html',
    styleUrl: 'dossiers-liste.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})

export class DossiersListeComponent
{
    title:string = "Liste des dossiers" 
    pageSize:number = getElementsOnPage()
    pageIndex:number = 1
    id_user:number
    totalPages!:number
    totalResult!:number

    data_header_table:object = 
    {
      // "badge":"",
      "ref":"N°dossier MDE",
      "nom_dossier":"Nom du dossier",
      "num_contrat":"N°de contrat",
      "date":"Date saisine",
      "etat":"État",
      // "hors_delai":"Hors délai"
    }
    data_body_table: Dossiers[] = []
    isDataReady:boolean = false
  
    constructor
    (
      private dossierService:DossiersService,
      private sharedService:SharedService
    ) 
    {
      this.id_user = this.sharedService.getIdUser()
      this.createPagination(this.pageSize)
    }
  
    ngOnInit()
    {
      this.getDataDossier()
    }

    ngOnChanges(changes: SimpleChanges) 
    {
      if ('search' in changes) 
      {
        this.getDataDossier()
      }
    }

    changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
    {  
      this.pageIndex = changeDisplay == false ? currentPage : 1 
      this.pageSize = pageSize
      this.getDataDossier()
    }

    async createPagination(pageSize:number)
    {
      (await
        this.sharedService.getInfoPagination("dossiers_liste", this.id_user)).subscribe(data => {
        this.totalResult = data[0].total_result
        this.totalPages = Math.ceil(this.totalResult / pageSize)
      })
    }
  
    getDataDossier(search:string="")
    {
      this.dossierService.getData(this.pageIndex, this.pageSize, this.id_user, search).subscribe((data:Dossiers[]) => {
        this.data_body_table = data
        this.isDataReady = true
      })
    }
}