import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfilService } from '../../services/profil/profil.service';
import { confirmPasswordValidator } from '../../helpers/custom-validators/password.validator';
import { SharedModule } from '../../modules/shared/shared.module';
import { ProfilIn, ProfilOut } from '../../interfaces/Profil';
import { SharedService } from '../../services/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnexionService } from '../../services/connexion/connexion.service';
import { BreadcrumbComponent } from '../../common/breadcrumb/breadcrumb.component';
import { confirmEmailValidator } from '../../class/custom_validators/email-validators';


// const upperLowerSymbolNumberRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
@Component({
    selector: 'app-profil',
    standalone: true,
    templateUrl: './profil.component.html',
    styleUrl: './profil.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})

export class ProfilComponent 
{
  title:string = "Mon profil"
  srcIconPassword:string = "/assets/icons/picto-oeil-cacher.png"
  hide_password:string = "/assets/icons/picto-oeil-cacher.png"
  show_password:string = "/assets/icons/picto-oeil-montrer.png"
  profil!: FormGroup
  isPasswordSectionVisible: boolean = false
  isEmailSectionVisible: boolean = false
  idUser!:number

  data: any = []
  srcIconTrue: string = "/assets/icons/picto-valide.png"
  srcIconFalse: string = "/assets/icons/picto-croix-rouge.png"
  passwordCheck:boolean = false
  errorMessage:string = ""
  isSubmitted:boolean = false
  emailExists:boolean = false
  limit:number = Object.freeze(200)
  readonly emailPattern = Object.freeze("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$")

  constructor
  (
    private profilService:ProfilService,
    private connexionService:ConnexionService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar
  )
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user

    this.getProfil()
    
    // const validators = [Validators.minLength(8), Validators.pattern(upperLowerSymbolNumberRegex)]
    const validators = [Validators.minLength(8)]

    this.profil = new FormGroup({
      password: new FormControl(""),
      new_password: new FormControl("", validators),
      confirm_password: new FormControl("", validators),
      email: new FormControl("", [Validators.email, Validators.pattern(this.emailPattern)]),
      confirm_email: new FormControl("", [Validators.email, Validators.pattern(this.emailPattern)])
    },
    {
      validators: [confirmPasswordValidator('new_password', 'confirm_password'), confirmEmailValidator("email","confirm_email")]
    })
  }

  getProfil()
  {
    this.profilService.getProfil(this.idUser).subscribe((data:ProfilIn[]) => {
      this.data = 
      [
        {name:"Civilité", value:data[0].civilite},
        {name:"Nom", value:data[0].nom},
        {name:"Prénom", value:data[0].prenom},
        {name:"Courriel", value:data[0].email}
      ]

      if(data[0].id_profil == 5 && data[0].entreprise != null)
      {
        this.data.unshift({name:"Entreprise", value:data[0].entreprise})
      }
    })
  }

  close()
  {
    this.isPasswordSectionVisible = false
    this.isEmailSectionVisible = false
    this.clearForm()
  }

  clearForm()
  {
    this.profil.get("password")?.setValue("")
    this.profil.get("new_password")?.setValue("")
    this.profil.get("confirm_password")?.setValue("")
    this.profil.get("email")?.setValue("")
    this.profil.get("confirm_email")?.setValue("")
    this.passwordCheck = false
    this.errorMessage = ""
    this.isSubmitted = false
    this.emailExists = false
  }

  togglePasswordSection()
  {
    this.isPasswordSectionVisible = !this.isPasswordSectionVisible
  }

  toggleEmailSection()
  {
    this.isEmailSectionVisible = !this.isEmailSectionVisible
  }

  togglePasswordVisibility(event: Event)
  {
    if(event.target instanceof HTMLImageElement)
    { 
      const img: HTMLImageElement = event.target

      if( img.src.split("assets")[1] === this.hide_password.split("assets")[1] ) 
      {
        img.src = this.show_password
        event.target.previousElementSibling?.setAttribute("type","text")
      }
      else 
      {
        img.src = this.hide_password
        event.target.previousElementSibling?.setAttribute("type","password")
      }
    }
  }

  isInvalid(controlName: string)
  {
    const control = this.profil.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  formInvalid()
  {
    this.profil.markAsDirty()
    this.profil.markAllAsTouched()
    console.log("invalid")
    return
  }

  checkPassword()
  {
    const password = this.profil.controls["password"].value
    this.profilService.checkPassword(password, this.idUser).subscribe(data => {
      if(data)
      {
        this.errorMessage = ""
        this.passwordCheck = true
      }
      else
      {
        this.errorMessage = "Le mot de passe saisi n'est pas correct, veuillez recommencer"
        this.formInvalid()
      }
    })
  }

  goToContactPage()
  {
    return this.sharedService.goToContactPage()
  }

  submit()
  {
    const new_email = this.profil.controls["email"].value
    const new_password = this.profil.controls["new_password"].value
    const confirm_password = this.profil.controls["confirm_password"].value

    this.isSubmitted = true

    // if(
    //     this.profil.invalid || 
    //     new_password !== confirm_password ||
    //     new_password === "" || confirm_password === "" ||
    //     new_password.length < 8 || confirm_password.length < 8
    //   )
    // {
    //   return this.formInvalid()
    // }
    if(
        this.profil.invalid 
      )
    {
      return this.formInvalid()
    }

    const form:ProfilOut =  { email:new_email, password: new_password, idUser: this.idUser}

    this.profilService.saveProfil(form).subscribe({
      next: (response) => {

        if(response == 0)
        {
          this.emailExists = true
          return
        }

        this.isSubmitted = false

        this.snackBar.open("Vos informations ont bien été modifiées", "Ok", {
          duration: 4000,
        })

        this.sharedService.postTableConnexion(this.idUser, 3, this.idUser).subscribe()

        sessionStorage.removeItem("loginIsNotAnEmail")
        sessionStorage.setItem("default_password", "false")
        this.close()
        this.getProfil()
      },
      error: (e:Error) => {
        console.log(e)

        this.snackBar.open(e.message, "J'ai compris", {
          duration: 5000,
        })
      }
    })
  }
}
