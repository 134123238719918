import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pagination } from '../../interfaces/Pagination';
import { ProfilService } from '../profil/profil.service';
import { MatDialog } from '@angular/material/dialog';
import { Dossiers_Data } from '../../interfaces/Dossiers';
import { Router } from '@angular/router';
import { DossiersService } from '../dossiers/dossiers.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SharedService 
{
  private readonly HOST:string  = environment.BASE_URL
  isButtonBurgerVisible:boolean = true
  thresholdTablet :number = 1400
  thresholdMobile :number = 850
  addBackdrop = new BehaviorSubject<boolean>(false)
  isSearchBarEmpty = new BehaviorSubject<boolean>(true)

  constructor
  (
    private httpClient:HttpClient,
    private profilService: ProfilService,
    private dossierService: DossiersService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  getDataPopup(id:number):Observable<any>
  {
    const url = `${this.HOST}/popup`

    return this.httpClient.get(url, 
      {
        params : 
        {
          id:id
        }
      })
  }

  async getInfoPagination(map_sql_string:string, id:number):Promise<Observable<Pagination[]>>
  {
    const url = `${this.HOST}/pagination`

    if( map_sql_string )
    {
      return this.httpClient.get<Pagination[]>(url, 
        {
          params : 
          {
            map_sql_string,
            id
          }
        })
    }
    else
    {
      return of()
    }

  }

  getPaysList():Observable<{ID_PAYS:number, NOM_PAYS:string}[]>
  {
    const url = `${this.HOST}/pays`

    return this.httpClient.get<{ID_PAYS:number, NOM_PAYS:string}[]>(url)
  }

  getIdUser()
  {
    return (sessionStorage.getItem("id_user") as any)
  }

  getUserDossier(id_user:string):Observable<Boolean>
  {
    const url = `${this.HOST}/user-dossiers`

    return this.httpClient.post<Boolean>(url, {id_user})
  }

  getParam(value:string) // avoir des paramètres empèche le rafraichissement de la page sur le serveur 
  {
    const url_fragments = this.router.url.split("/")
    const match = url_fragments.find(url => url.includes(value))
    if(!match || match == undefined) return

    return match.split("_").slice(-1)[0]
  }

  goToContactPage()
  {
    const id_user = this.getIdUser()

    this.profilService.getProfil(id_user).subscribe(data => {
        const { civilite, ...profil} = data[0]

        const link = document.createElement("a")

        const data_form_contact = JSON.stringify(Object.values(profil))

        link.href = `${environment.CONTACT_LINK}?data=${btoa(data_form_contact)}`
        link.target = "_blank"
        link.click()
    })
  }

  openDialog(component:any)
  {
    const dialogRef = this.dialog.open(component, {disableClose: true})
    
    dialogRef.afterClosed().subscribe(()=> {
      console.log("dialog closed")
    })
  }

  blurPage()
  {    
    this.addBackdrop.next(true)

    const snackbarRef = this.snackBar.open("Veuillez patienter ...", "Ok")

    snackbarRef.onAction().subscribe(() => {
      this.focusPage() 
    })

    //* Quoi qu'il arrive, le flou d'arrière plan, reste au maximum 10 secondes
    setTimeout(() => {
      this.focusPage()
    }, 1000 * 10)
  }

  focusPage()
  {
    this.addBackdrop.next(false)
  }

  showSidebar()
  {
    (document.getElementById("sidebar") as HTMLElement).style.display = 'initial'
  }

  hideSidebar()
  {
    (document.getElementById("sidebar") as HTMLElement).style.display = 'none'
  }

  switchToTabletMode()
  {
    return window.innerWidth <= this.thresholdTablet ? true : false
  }

  switchToMobileMode()
  {
    return window.innerWidth <= this.thresholdMobile ? true : false
  }

  postTableConnexion(id_user:number|string, id_action:number|string, id_table:number|string):Observable<number>
  {
    const url = `${this.HOST}/liste-actions`

    return this.httpClient.post<number>(url, {id_user, id_action, id_table})
  }

  markDocumentAsRead(id_document:number, id_user:number)
  {
    this.dossierService.updateDocument([id_document]).subscribe(data => {
      if(data) 
      {
        this.postTableConnexion(id_user, 502, id_document).subscribe()
      }
    })
  }
}