import { environment } from "../../../environments/environment";

export function verif() 
{
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) 
    {
      const originalMethod = descriptor.value; // Store the original method
  
      descriptor.value = async function (...args: any[]) 
      {
        const params = args[0]
        const url = environment.BASE_URL+"/check"
        const options = 
        {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ref: params.ref , id_dossier: params.id_dossier, id_user:sessionStorage.getItem("id_user")})
        }
        const fetch_url = await fetch(url, options)
        const response = await fetch_url.json()
        
        if(!response || response.length == 0)
        {
            sessionStorage.clear()
        }
        
        // Call the original method
        const result = originalMethod.apply(this, args)
        return result// Return the result of the original method
      }
  
      return descriptor
    }
  }
  