import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { SharedService } from '../../services/shared/shared.service';
import { InputListenerDirective } from '../../directives/input-listener/input-listener.directive';
import { NOT_ALLOWED_CHARACTERS } from '../../helpers/characters/not-allowed-characters';

@Component({
  selector: 'app-searchbar',
  standalone: true,
  imports: [ReactiveFormsModule, InputListenerDirective],
  templateUrl: './searchbar.component.html',
  styleUrl: './searchbar.component.css'
})
export class SearchbarComponent 
{
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>()
  searchForm:FormGroup = new FormGroup({
    searchValue: new FormControl("")
  })

  constructor(private sharedService:SharedService){}

  ngOnInit()
  {
    this.searchForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe( data =>  {
        if(NOT_ALLOWED_CHARACTERS.includes(data.searchValue)) return

        if(data.searchValue.trim() != "" && data.searchValue.length > 0)
        {
          this.sharedService.isSearchBarEmpty.next(false)
        }
        this.onSearch.emit(data.searchValue)
      })
  }

  clearSearchBar()
  {
    this.sharedService.isSearchBarEmpty.next(true)
    this.searchForm.get('searchValue')?.setValue('')
  }
}
