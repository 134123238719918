import { Component } from '@angular/core';
import { SharedModule } from './modules/shared/shared.module';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    imports: [SharedModule]
})
export class AppComponent 
{
  title = 'Espace Abonnés'
}
