import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Saisine, docSaisine } from '../../interfaces/Saisine';

@Injectable({
  providedIn: 'root'
})
export class SaisineService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient
  ) { }

  getSaisine(ref:string):Observable<{dossier:Saisine[], documents:docSaisine[]}>
  {
    const url = `${this.HOST}/saisine`

    return this.httpClient.get<{dossier:Saisine[], documents:docSaisine[]}>(url,
      {
        params : 
        {
          ref:ref
        }
      })
  }
  
  updateAdresse(form:{adresse:string, cp:string, commune:string, id_pays:number, id_dossier:number}):Observable<number>
  {
    const url = `${this.HOST}/saisine`

    return this.httpClient.put<number>(url, {form})
  }
}
