import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NavigationService } from '../navigation/navigation.service';
import { Autologin, ConnexionOut } from '../../interfaces/ConnexionOut';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NavigationEnd, Router } from '@angular/router';
import { HttpCancelService } from '../http-cancel/http-cancel.service';


@Injectable({
  providedIn: 'root'
})
export class ConnexionService 
{
  private readonly HOST:string  = environment.BASE_URL
  refresh_token = new BehaviorSubject<boolean>(false)

  constructor
  (
    private httpClient:HttpClient,
    private navigationService: NavigationService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private httpCancelService: HttpCancelService
  ) 
  {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {

      const paramsMap = this.router.parseUrl(event.url).queryParamMap
      const ida = paramsMap.get("ida")
      const idc = paramsMap.get("idc")
      const idp = paramsMap.get("idp")
      const h = paramsMap.get("h")

      const is_autolog = event.url.startsWith('/autologin') && ida && idc && idp && h 

      if(is_autolog) return

      if( event.url != '/connexion')
      {       
        this.isTokenExpired()
        if( this.refresh_token.getValue() ) 
        {
          this.refreshToken().subscribe()
        }
      }
    })
  }

  refreshToken():Observable<{token:string, isExpired:boolean}>
  {
    const url = `${this.HOST}/refresh`

    return this.httpClient
            .post<{token:string, isExpired:boolean}>(url, {token:this.getToken(),refresh_token: this.getRefreshToken()})
            .pipe(
              tap((data) => {
                const token = data.token
                const isExpired = data.isExpired
    
                if(token == "null" && isExpired)
                {
                  this.logout()
                }
                else
                {
                  sessionStorage.setItem("access_token", token)
                }
                this.refresh_token.next(false)
              })
            )
  }

  autologin(params:Map<string, string>):Observable<Autologin>
  {
    const url = `${this.HOST}/autologin`

    return this.httpClient.post<Autologin>(url, Array.from(params))
      .pipe(
        tap((res: Autologin) => {
          sessionStorage.setItem("access_token", res.access_token)
        })
      )
  }

  login(login:string, password:string):Observable<ConnexionOut>
  {
    const url = `${this.HOST}/connexion`

    return this.httpClient.post<ConnexionOut>(url, {login, password})
      .pipe(
        tap((res: ConnexionOut) => {
          sessionStorage.setItem("access_token", res.access_token)
        })
      )
  }

  updateLogin(login:string, id_user:string):Observable<number>
  {
    const url = `${this.HOST}/connexion/update-login`

    return this.httpClient.post<number>(url, {login, id_user})
  }

  checkIfUserExistsWithSameCredentials(email:string, login:string):Observable<{emailExist:boolean}[]>
  {
    const url = `${this.HOST}/check-credentials`

    return this.httpClient.post<{emailExist:boolean}[]>(url, {email, login})
  }

  getUserByEmailAndLogin(email:string, login:string):Observable<ConnexionOut>
  {
    const url = `${this.HOST}/user`

    return this.httpClient.post<ConnexionOut>(url, {email, login})
  }

  async forgotPassword(email:string):Promise<Observable<any>>
  {
    const url = `${this.HOST}/connexion/mot-de-passe-oublie`

    return this.httpClient.post(url, {email})
  }

  logout()
  {
    this.httpCancelService.cancelPendingRequests()

    localStorage.removeItem("access_token")
    localStorage.removeItem("expiresIn")

    sessionStorage.clear()
    this.navigationService.navigateTo("/connexion",[null])
  }

  logoutIfSessionExpires()
  {
    if( this.isRefreshTokenExpired() || (this.isTokenExpired() && this.isRefreshTokenExpired()) ) return this.logout()
  }

  getToken()
  {
    return sessionStorage.getItem("access_token")
  }

  getRefreshToken()
  {
    return sessionStorage.getItem("refresh_token")
  }

  isTokenExpired()
  {
    const token = sessionStorage.getItem("access_token")
    const isExpired = token ? this.jwtHelper.isTokenExpired(token) : true

    if( isExpired ) this.refresh_token.next(true)
    return this.jwtHelper.isTokenExpired(token)
  }

  isRefreshTokenExpired()
  {
    const token = sessionStorage.getItem("refresh_token")
    const isExpired = token ? this.jwtHelper.isTokenExpired(token) : true
    return isExpired
  }


  isLoggedIn():boolean
  { 
    const token = sessionStorage.getItem("access_token")
    const refresh_token = sessionStorage.getItem("refresh_token")
    return token && refresh_token ? true : false
  }
}
