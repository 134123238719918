import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageOut } from '../../interfaces/MessageOut';
import { Dossiers, DossiersDetails } from '../../interfaces/Dossiers';
import { Document } from '../../interfaces/Document';
import { Message, MessageIn } from '../../interfaces/MessageIn';
import { Suivi } from '../../interfaces/Suivi';

@Injectable({
  providedIn: 'root'
})
export class DossiersService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient
  ) { }


  getData(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/dossiers-liste`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getDetails(ref:string, id:string|number):Observable<DossiersDetails[]>
  {
    const url = `${this.HOST}/dossiers-details`

    return this.httpClient.get<DossiersDetails[]>(url, 
      {
        params : 
        {
          ref:ref,
          id:id
        }
      })
  }

  getSuivi(ref:string, id_user:number):Observable<Suivi[]>
  {
    const url = `${this.HOST}/dossiers-details/suivi`

    return this.httpClient.get<Suivi[]>(url, 
      {
        params : 
        {
          ref:ref,
          id_user:id_user
        }
      })
  }

  getAllDocuments(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Document[]>
  {
    const url = `${this.HOST}/dossiers/documents`

    return this.httpClient.get<Document[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getAllMessages(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<MessageIn[]>
  {
    const url = `${this.HOST}/dossiers/messages`

    return this.httpClient.get<MessageIn[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getDocuments(ref:string, id_dossier:number, id_user:number):Observable<Document[]>
  {
    const url = `${this.HOST}/dossiers-details/documents`

    return this.httpClient.get<Document[]>(url, 
      {
        params : 
        {
          ref,
          id_dossier,
          id_user
        }
      })
  }

  getMessages(ref:string, id_dossier:number, id_user:number):Observable<Message[]>
  {
    const url = `${this.HOST}/dossiers-details/messages`

    return this.httpClient.get<Message[]>(url, 
      {
        params : 
        {
          ref,
          id_dossier,
          id_user
        }
      })
  }

  postMessage(message:MessageOut):Observable<{INSERTED_ID:number}[]>
  {
    const url = `${this.HOST}/dossiers-details/messages`

    return this.httpClient.post<{INSERTED_ID:number}[]>(url, message)
  }

  updateMessage(id_message:number):Observable<number>
  {
    const url = `${this.HOST}/dossiers-details/messages`

    return this.httpClient.put<number>(url,       
    {
      params : 
      {
          id_message:id_message
      }
    })
  }

  updateDocument(id_document:number[], id_message:number[]=[]):Observable<number>
  {
    const url = `${this.HOST}/dossiers-details/documents`

    return this.httpClient.put<number>(url,       
    {
      params : 
      {
          id_document:id_document,
          id_message:id_message
      }
    })
  }
}
