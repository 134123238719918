<header class="header d-flex justify-content-between">
    <div class="brand d-flex p-1">
        <img src="/assets/img/logo-mediation-eau.png" alt="logo mediation eau">
        <h6 class="grey align-self-end">{{ nom_espace + profil  }}</h6>
    </div>
    <div class="user">
        <div class="hello">
            <div class="small right bold grey-dark">
                @if( isDataReady )
                {
                    Bonjour, {{civilite}} {{ nom }} {{ prenom }}
                }
            </div>
            <div class="small right grey">Derniere connexion le {{ last_connexion }}</div>
        </div>
        <div class="profile relative">
            <div class="dropdown">
                <img class="img picto-x2 cursor me-5 dropdown-toggle" src="/assets/img/picto-profil.png" data-bs-toggle="dropdown" aria-expanded="false">
                  
                <ul class="dropdown-menu">
                    <li class="dropdown-item cursor" routerLink="/profil" routerLinkActive="router-link-active">Mon profil</li>
                    <li class="dropdown-item cursor" routerLink="/liste-actions" routerLinkActive="router-link-active">Liste des actions</li>
                    <li class="dropdown-item cursor test" ><img class="picto-deconnexion" src="/assets/icons/picto-deconnexion-black.png" alt="déconnexion"><a (click)="deconnexion()">Déconnexion</a></li>
                </ul>
            </div>
        </div>
    </div>
</header>
