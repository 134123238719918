import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
// import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './class/custom-mat-paginator-intl';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from './../environments/environment';
import { authInterceptor } from './helpers/interceptor/authConfig.interceptor';


export function tokenGetter() 
{
  return sessionStorage.getItem("access_token");
}

const ALLOWED_DOMAINS:string[]  = environment.ALLOWED_DOMAINS

export const appConfig: ApplicationConfig = {
  providers: 
  [
    importProvidersFrom(
      JwtModule.forRoot({
          config: {
              tokenGetter: tokenGetter,
              allowedDomains: ALLOWED_DOMAINS,
          }
      })
    ),
    provideRouter(routes, withComponentInputBinding()), 
    // provideClientHydration(), 
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])), 
    provideAnimations(), 
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}
  ]
};


