import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UploadService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient
  ) { }

  async uploadFile(files:FormData, folder:string, subFolder:string, id_user:number, id_dossier:number):Promise<Observable<{ID:number}[]>>
  {
    const url  = `${this.HOST}/upload`

    return this.httpClient.post<{ID:number}[]>(url, files, 
      {
        params:
        {
          folder:folder,
          subFolder:subFolder,
          id_user:id_user,
          id_dossier:id_dossier
        }
      })
  }
}
