import { AbstractControl, ValidatorFn } from '@angular/forms';

export function zipValidator(): ValidatorFn 
{
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value

    const valid = /^[0-9]{5}$|^(BP|CS|SP|CEDEX) \d{5}$/.test(value)
    return valid ? null : { invalidPostalCode: { value: value } }
  }
}
