import { Component } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-backdrop',
  standalone: true,
  imports: [],
  templateUrl: './backdrop.component.html',
  styleUrl: './backdrop.component.css'
})
export class BackdropComponent 
{
  constructor
  (
    private sharedService: SharedService
  ) {}

  ngOnInit()
  {
    this.sharedService.addBackdrop.subscribe(data => {
      const div =  document.querySelector("div")

      if( div && data == true )
      {
        this.addBackdrop(div)
      }
      else if( div && data == false )
      {
        this.removeBackdrop(div)
      }
    })
  }

  addBackdrop(element:HTMLElement)
  {
    element.style.zIndex = "999"
    element.style.backdropFilter = "blur(3px)"
  }

  removeBackdrop(element:HTMLElement)
  {
    element.style.zIndex = "-10"
    element.style.backdropFilter = "none"
  }

}
