import { Component, SimpleChanges } from '@angular/core';
import { SharedModule } from "../../../modules/shared/shared.module";
import { Dossiers } from '../../../interfaces/Dossiers';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { SharedService } from '../../../services/shared/shared.service';
import { getElementsOnPage } from '../../../helpers/functions/GetElementsOnPage';

@Component({
  selector: 'app-documents-non-lus',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './documents-non-lus.component.html',
  styleUrl: './documents-non-lus.component.css'
})
export class DocumentsNonLusComponent 
{
  title:string = "Documents non consultés" 
  pageSize:number = getElementsOnPage()
  pageIndex:number = 1
  totalPages!:number
  totalResult!:number
  data_header_table = 
  {
    "badge":"",
    "date":"Date",
    "ref":"N°dossier",
    "expediteur":"Expéditeur",
    "etat":"État",
    "msg":"Rattaché au message",
    "fichier":"Fichier",
    "poids":"Poids",
    "telecharger":""
  }
  data_body_table: Document[] = []
  isDataReady:boolean = false
  id_user!:number

  constructor
  (
    private sharedService:SharedService,
    private dashboardService: DashboardService
  ) 
  {
    const idUser = this.sharedService.getIdUser()
    this.id_user = idUser
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getData()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getData()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getData()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("documents_non_lus", this.id_user)).subscribe(data => {
      this.totalResult = data[0].total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getData(search:string="")
  {
    this.dashboardService.getDocumentsNonLus(this.pageIndex, this.pageSize, this.id_user, search).subscribe((data:Document[]) => {
        this.data_body_table = data
        this.isDataReady = true
    })
  }
}
