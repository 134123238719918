import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { CryptoService } from '../services/crypto/crypto.service';
import { SharedService } from '../services/shared/shared.service';


@Component({
  standalone: true,
  imports: [],
  template: `
  <div class="dialog d-flex">
    <div class="col-4 bg-blue white bold dialog-title">SAISINE (SUITE)</div>
    <div class="col-8 bg-white blue-dark-1 dialog-content-group">
        <div class="dialog-content">
            <p>Bonjour et bienvenue sur votre espace personnel.</p>
            <p>Votre compte est désormais actif.</p>
            <p>Veuillez cliquer sur le bouton ci-dessous pour compléter votre formulaire de saisine.</p>

            <button class="change-password float-end" (click)="navigateToSaisine()">
                Continuer ma saisine
                <img class="white-filter mx-1" src="/assets/icons/triangle.png" style="transform: rotate(-90deg)" height="15">
            </button>
        </div>
    </div>
  </div>
  `
})

export class SuiteSaisine
{
  constructor
  (
    private dialog: MatDialog,
    private cryptoService: CryptoService,
    private sharedService: SharedService
  ){}

  navigateToSaisine()
  {
    const url = environment.LINK_SAISINE

    const id_user = this.sharedService.getIdUser()

    this.cryptoService.encryptData(id_user.toString()).subscribe(data => {
        const parameterValue = `?data=${data.iv}:${data.encrypted}`
        window.open(url+parameterValue, '_blank')
    })

    this.closeDialog()
  }

  closeDialog()
  {
    this.dialog.closeAll()
  }
}
