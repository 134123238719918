import { Component } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NavigationService } from '../../services/navigation/navigation.service';
import { TileMessageDashboard } from '../../interfaces/TileMessageDashboard';
import { SharedModule } from '../../modules/shared/shared.module';
import { SharedService } from '../../services/shared/shared.service';

@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: 'home.component.html',
    styleUrl: 'home.component.css',
    imports: [MatProgressSpinnerModule, SharedModule]
})

export class HomeComponent
{
    nb_clos!:number
    nb_en_cours!:number
    nb_message_non_lus!:number
    nb_document_non_lus!:number
    derniers_messages: TileMessageDashboard[] = []
    id_user!:number

    constructor
    (
        private dashboardService: DashboardService,
        private navigationService: NavigationService,
        private sharedService: SharedService
    ) 
    {
        this.id_user = this.sharedService.getIdUser()
    }

    ngOnInit()
    {
        this.getData()
    }

    getData()
    {
        this.countDossiersClos()
        this.countDossiersEnCours()
        this.countMessagesNonLus()
        this.countDocumentsNonLus()
        this.getLastMessages()
    }

    countMessagesNonLus()
    {
        this.dashboardService.countMessagesNonLus(this.id_user).subscribe(data => {
            if(data) this.nb_message_non_lus = data[0].total_result
        })
    }

    countDocumentsNonLus()
    {
        this.dashboardService.countDocumentsNonLus(this.id_user).subscribe(data => {
            if(data) this.nb_document_non_lus = data[0].total_result
        })
    }

    countDossiersEnCours()
    {
        this.dashboardService.countDossiersEnCours(this.id_user).subscribe(data => {
            if(data) this.nb_en_cours = data[0].total_result
        })
    }

    countDossiersClos()
    {
        this.dashboardService.countDossiersClos(this.id_user).subscribe(data => {
            if(data) this.nb_clos = data[0].total_result
        })
    }

    getLastMessages()
    {
        this.dashboardService.getLastMessages(this.id_user).subscribe(data => {
            if(data) this.derniers_messages = data
        })
    }

    goToMessage(ref:string, id_dossier:number)
    {
        this.navigationService.navigateTo(`dossiers/details/ref_${ref}/id_dossier_${id_dossier}/onglet_messages`, [null])
    }

    goToMessageNonLus()
    {
        this.navigationService.navigateTo(`tableau-de-bord/messages-non-lus`, [null])
    }

    goToDossiersEnCours()
    {
        this.navigationService.navigateTo(`tableau-de-bord/dossiers-en-cours`, [null])
    }

    goToDossiersClos()
    {
        this.navigationService.navigateTo(`tableau-de-bord/dossiers-clos`, [null])
    }

    goToDocumentNonLus()
    {
        this.navigationService.navigateTo(`tableau-de-bord/documents-non-consultes`, [null])
    }

    goToProfil()
    {
        this.navigationService.navigateTo(`profil`, [null])
    }
}